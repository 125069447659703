// Asyncstorage
export const SET_ITEM_SUCCESS = 'SET_ITEM_SUCCESS';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAIL = 'GET_ITEM_FAIL';

// Muser
// user
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GENERAL_SETTINGS = 'GENERAL_SETTINGS';
export const MOUNT_COMPONENT = 'MOUNT_COMPONENT';
export const GENERAL_SETTINGS_FAIL = 'GENERAL_SETTINGS_FAIL';

export const SCANPACK_SETTINGS = 'SCANPACK_SETTINGS';
export const SCANPACK_SETTINGS_FAIL = 'SCANPACK_SETTINGS_FAIL';

export const GET_BOTH_SETTINGS = 'GET_BOTH_SETTINGS';
export const GET_BOTH_SETTINGS_FAIL = 'GET_BOTH_SETTINGS_FAIL';

export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_SUCCESS_FAIL = 'USER_INFO_SUCCESS_FAIL';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const SEND_PASSWORD_RESET_EMAIL_FAIL = 'SEND_PASSWORD_RESET_EMAIL_FAIL';
export const IS_EMAIL_SEND = 'IS_EMAIL_SEND';
export const IS_BUG_SUPPORT_POPUP_OPEN = 'IS_BUG_SUPPORT_POPUP_OPEN';
export const GET_ALL_USERS = 'GET_ALL_USERS';

// url
export const UPDATE_URL = 'UPDATE_URL';
export const GET_URL = 'GET_URL';

// scanpack
export const CLICK_SCAN = 'CLICK_SCAN';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const SEARCH_SCANPACK_ORDER = 'SEARCH_SCANPACK_ORDER';
export const SEARCH_SCANPACK_ORDER_FAIL = 'SEARCH_SCANPACK_ORDER_FAIL';
export const SUBMIT_LOG = 'SUBMIT_LOG';
export const SUBMIT_LOG_FAIL = 'SUBMIT_LOG_FAIL';
export const SCANPACK_BUG_REPORT = 'SCANPACK_BUG_REPORT';
export const SCANPACK_BUG_REPORT_FAIL = 'SCANPACK_BUG_REPORT_FAIL';
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const RESET_SCANPACK_ORDER = 'RESET_SCANPACK_ORDER';

// menu
export const IS_OPEN = 'IS_OPEN';
export const IS_UNMOUNT = 'IS_UNMOUNT';

// listDisplay
export const IS_LIST_MODE = 'IS_LIST_MODE';

//haptics mode
export const IS_HAPTICS_MODE = 'IS_HAPTICS_MODE';

// swap upc and location
export const IS_SWAP = 'IS_SWAP';

// notification popUp
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';

// product
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';
export const PRODUCT_DETAIL_FAIL = 'PRODUCT_DETAIL_FAIL';
export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';
export const GET_ALL_PRODUCT_FAIL = 'GET_ALL_PRODUCT_FAIL';

export const GET_SEARCH_PRODUCT = 'GET_SEARCH_PRODUCT';
export const CLEAR_SEARCH_PRODUCT = 'CLEAR_SEARCH_PRODUCT';
export const GET_SEARCH_PRODUCT_FAIL = 'GET_SEARCH_PRODUCT_FAIL';

export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const UPDATE_PRODUCT_INFO_FAIL = 'UPDATE_PRODUCT_INFO_FAIL';
export const UPDATE_PRODUCT_ALIAS = 'UPDATE_PRODUCT_ALIAS';
export const UPDATE_PRODUCT_ALIAS_FAIL = 'UPDATE_PRODUCT_ALIAS_FAIL';

export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST';
export const UPDATE_PRODUCT_LIST_FAIL = 'UPDATE_PRODUCT_LIST_FAIL';
export const IS_LOADING = 'IS_LOADING';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const ADD_PDF = 'ADD_PDF';
export const GET_PDFS = 'GET_PDFS';
export const IS_PRODUCT_CREATED = 'IS_PRODUCT_CREATED';
export const IS_PRODUCT_UPDATED = 'IS_PRODUCT_UPDATED';
export const IS_PRODUCT_LOADING = 'IS_PRODUCT_LOADING';
// headers
export const ORDER_BACK_BUTTON = 'ORDER_BACK_BUTTON';

// order
export const IS_PRODUCT_BARCODE_ADDED = 'IS_PRODUCT_BARCODE_ADDED';

// order tags
export const GET_ORDERS_TAGS = 'GET_ORDERS_TAGS';

//  order
export const ORDER_LIST = 'ORDER_LIST';
//Multibox changes.
export const ORDER_DETAIL_LOADER = 'ORDER_DETAIL_LOADER';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';
export const ORDER_DETAIL = 'ORDER_DETAIL';
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL';
export const SEARCH_ORDER = 'SEARCH_ORDER';
export const SEARCH_ORDER_COUNT = 'SEARCH_ORDER_COUNT';
export const SEARCH_ORDER_FAIL = 'SEARCH_ORDER_FAIL';
export const REMOVE_BOX_ITEMS = 'REMOVE_BOX_ITEMS';
export const REMOVE_BOX = 'REMOVE_BOX';
export const ADD_BOX = 'ADD_BOX';
export const PRINT_SLIP_MULTIBOX = 'PRINT_SLIP_MULTIBOX';
export const UPDATE_ORDER_QUANTITY = 'UPDATE_ORDER_QUANTITY';
export const UPDATE_ORDER_QUANTITY_FAIL = 'UPDATE_ORDER_QUANTITY_FAIL';

export const ADD_ORDER_ITEMS = 'ADD_ORDER_ITEMS';
export const ADD_ORDER_ITEMS_FAIL = 'ADD_ORDER_ITEMS_FAIL';

export const REMOVE_ORDER_ITEMS = 'REMOVE_ORDER_ITEMS';
export const REMOVE_ORDER_ITEMS_FAIL = 'REMOVE_ORDER_ITEMS_FAIL';

export const UPDATE_ORDER_INFO = 'UPDATE_ORDER_INFO';
export const UPDATE_ORDER_INFO_FAIL = 'UPDATE_ORDER_INFO_FAIL';

export const ADD_ORDER_IMAGE = 'ADD_ORDER_IMAGE';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';
export const ADD_ORDER_IMAGE_FAIL = 'ADD_ORDER_IMAGE_FAIL';
export const ADD_ORDER_IMAGE_LOADING = 'ADD_ORDER_IMAGE_LOADING';
export const ADD_ORDER_IMAGE_CLEAR = 'ADD_ORDER_IMAGE_CLEAR';
export const REMOVE_PACKING_IMAGE_LOADING = 'REMOVE_PACKING_IMAGE_LOADING';
export const REMOVE_PACKING_IMAGE_DONE = 'REMOVE_PACKING_IMAGE_DONE';
export const REMOVE_PACKING_IMAGE_FAIL = 'REMOVE_PACKING_IMAGE_FAIL';

export const ADD_ITEM_BOX = 'ADD_ITEM_BOX';
export const CREATE_ORDER = 'CREATE_ORDER';
export const IS_ORDER_CREATED = 'IS_ORDER_CREATED';
export const ARE_ORDERS_UPDATED = 'ARE_ORDERS_UPDATED';

// import order
export const IMPORT_ORDER_FAIL = 'IMPORT_ORDER_FAIL';
export const IMPORT_ORDER_SUCCESS = 'IMPORT_ORDER_SUCCESS';
export const IMPORT_ORDER_STATUS_SUCCESS = 'IMPORT_ORDER_STATUS_SUCCESS';
export const IMPORT_ORDER_STATUS_FAIL = 'IMPORT_ORDER_STATUS_FAIL';
export const FULL_ORDER_SCAN_CHECK = 'FULL_ORDER_SCAN_CHECK';
export const DETECT_ORDERS_DISCREPANCIES = 'DETECT_ORDERS_DISCREPANCIES';

export const ALL_KIT_PRODUCT = 'ALL_PRODUCT';
export const ALL_KIT_PRODUCT_FAIL = 'ALL_PRODUCT_FAIL';

export const GET_KIT_PRODUCT = 'GET_KIT_PRODUCT';
export const GET_KIT_PRODUCT_FAIL = 'GET_KIT_PRODUCT_FAIL';

export const REMOVE_KIT_PRODUCT = 'REMOVE_KIT_PRODUCT';
export const REMOVE_KIT_PRODUCT_FAIL = 'REMOVE_KIT_PRODUCT_FAIL';

export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_HTML_IMAGE = 'ADD_HTML_IMAGE';
export const ADD_IMAGE_FAIL = 'ADD_IMAGE_FAIL';

export const RECORD_EXCEPTION = 'RECORD_EXCEPTION';
export const CLEAR_EXCEPTION = 'CLEAR_EXCEPTION';

export const GET_SHIPMENT_ID_SUCCESS = '';
export const GET_SHIPMENT_ID_FAIL = '';

//Shipping Label

export const CARRIER_LOADING = 'CARRIER_LOADING';

export const GET_SS_LABEL = 'GET_SS_LABEL';
export const GET_SS_LABEL_FAIL = 'GET_SS_LABEL_FAIL';

export const FETCH_REAL_TIME_RATES_SELECTED = 'FETCH_REAL_TIME_RATES_SELECTED';
export const FETCH_REAL_TIME_RATES_SELECTED_FAIL =
  'FETCH_REAL_TIME_RATES_SELECTED_FAIL';

export const FETCH_REAL_TIME_RATES = 'FETCH_REAL_TIME_RATES';
export const FETCH_REAL_TIME_RATES_FAIL = 'FETCH_REAL_TIME_RATES_FAIL';

export const UPDATE_ORDER_ADDRESS = 'UPDATE_ORDER_ADDRESS';
export const UPDATE_ORDER_ADDRESS_FAIL = 'UPDATE_ORDER_ADDRESS_FAIL';

export const SET_CARRIER_VISIBILITY = 'SET_CARRIER_VISIBILITY';
export const SET_CARRIER_VISIBILITY_FAIL = 'SET_CARRIER_VISIBILITY_FAIL';

export const SET_RATE_VISIBILITY = 'SET_RATE_VISIBILITY';
export const SET_RATE_VISIBILITY_FAIL = 'SET_RATE_VISIBILITY_FAIL';

export const CREATE_SS_LABEL = 'CREATE_SS_LABEL';
export const CREATE_SS_LABEL_FAIL = 'CREATE_SS_LABEL_FAIL';

export const SET_LABEL_SHORTCUT = 'SET_LABEL_SHORTCUT';
export const SET_LABEL_SHORTCUT_FAIL = 'SET_LABEL_SHORTCUT_FAIL';

export const PURCHASE_LOADING = 'PURCHASE_LOADING';
export const PURCHASE_LOADING_DONE = 'PURCHASE_LOADING_DONE';

export const SET_CONTRACTED_CARRIER = 'SET_CONTRACTED_CARRIER';
export const SET_CONTRACTED_CARRIER_FAIL = 'SET_CONTRACTED_CARRIER_FAIL';

export const SET_PRESETS = 'SET_PRESETS';
export const SET_PRESETS_FAIL = 'SET_PRESETS_FAIL';

export const SET_STATUS_MULTI_BOX = 'SET_STATUS_MULTI_BOX';

export const SET_CURRRENT_ORDER = 'SET_CURRRENT_ORDER';

export const CHECK_IMPORT_ORDER_STATUS_SUCCESS =
  'CHECK_IMPORT_ORDER_STATUS_SUCCESS';
export const CHECK_IMPORT_ORDER_STATUS_FAIL = 'CHECK_IMPORT_ORDER_STATUS_FAIL';

//  order tag Priority

export const GET_ORDER_TAG_PRIORITY = 'GET_ORDER_TAG_PRIORITY';
export const GET_ORDER_TAG_PRIORITY_FAIL = 'GET_ORDER_TAG_PRIORITY_FAIL';
export const GET_ORDER_TAG_PRIORITY_LOADING = 'GET_ORDER_TAG_PRIORITY_LOADING';
export const GET_ORDER_TAG_PRIORITY_ERROR = 'GET_ORDER_TAG_PRIORITY_ERROR';
export const UPDATE_ORDER_TAG_PRIORITY = 'UPDATE_ORDER_TAG_PRIORITY';
export const UPDATE_ORDER_TAG_PRIORITY_FAIL = 'UPDATE_ORDER_TAG_PRIORITY_FAIL';
export const CREATE_ORDER_TAG_PRIORITY = 'CREATE_ORDER_TAG_PRIORITY';
export const CREATE_ORDER_TAG_PRIORITY_FAIL = 'CREATE_ORDER_TAG_PRIORITY_FAIL';
export const UPDATE_ORDER_TAG_PRIORITY_POSITION =
  'UPDATE_ORDER_TAG_PRIORITY_POSITION';
export const DELETE_ORDER_TAG_PRIORITY = 'DELETE_ORDER_TAG_PRIORITY';

export const UPDATE_ORDER_TAG_PRIORITY_POSITION_LOADING =
  'UPDATE_ORDER_TAG_PRIORITY_POSITION_LOADING';
export const UPDATE_ORDER_TAG_PRIORITY_POSITION_ERROR =
  'UPDATE_ORDER_TAG_PRIORITY_POSITION_ERROR';
export const ADD_TAG = 'ADD_TAG';

export const STATISTICS_LIST = 'STATISTICS_LIST';
export const STATISTICS_LIST_FAIL = 'STATISTICS_LIST_FAIL';

export const STATISTICS_TODAY_VS_YESTERDAY_LIST =
  'STATISTICS_TODAY_VS_YESTERDAY_LIST';
export const STATISTICS_TODAY_VS_YESTERDAY_LIST_FAIL =
  'STATISTICS_TODAY_VS_YESTERDAY_LIST_FAIL';

export const STATISTICS_WITHIN15MIN_LIST = 'STATISTICS_WITHIN15MIN_LIST';
export const STATISTICS_WITHIN15MIN_LIST_FAIL =
  'STATISTICS_WITHIN15MIN_LIST_FAIL';

export const STATISTICS_WEEKLY_LIST = 'STATISTICS_WEEKLY_LIST';
export const STATISTICS_WEEKLY_LIST_FAIL = 'STATISTICS_WEEKLY_LIST_FAIL';
