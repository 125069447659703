const en = {
  session: {
    to_many_user_login:
      'Too many connections. Please logout other sessions to continue here.',
    connectionCheck: 'Unable to contact the server - Please ',
    checkConnection: 'check your connection',
    tryAgain: ' and try again.'
  },
  signIn: {
    enter_name: 'Enter Name in Field',
    saved_msg: 'Saved Successfully'
  },
  clickScanConfirmation: {
    scan_confirmation_pop_msg:
      'This is a note to packer. Confirmation is reqiuired for this note.',
    scan_confirmation_pop_errormsg:
      'Enter or scan your confirmation code to continue'
  },
  AddNextBarcode: {
    search_item_msg:
      'Scan the item you would like to add to this order to continue.',
    confirmation_code_msg:
      'Please scan or enter the confirmation code of a user with Order Edit Permissions.'
  },
  scanpackItem: {
    shippingEsaySameOrderTextAlert:
      'The following orders share a similar order number. Click to swtich.',
    shipStationSameOrderText:
      'The following orders share the same order number. Click to swtich.',
    Scanpack_Item_tracking_msg:
      "Doh! The Tracking Number You have scanned does not appear to be valid. If you're certain it is correct please have an admin check your Tracking Number Validation setting in Settings > System > Scan & Pack > Post Scanning Functions.",
    Scanpack_Item_shipping_msg_two:
      'To override this verification, enter a confitmation code with Order Edit Permissions to continue.',
    // Scanpack_qty_exceed_msg_part1: "The quantity entered exceeds the remaining",
    // Scanpack_qty_exceed_msg_part2: "pieces in the order. Please try again",
    // Scanpack_success_msg: "Save Changes Successful",
    Scanpack_type_count_msg:
      'Please scan the item once before triggering a type-in count.',
    Disable_type_count_msg: 'The Type-in Count feature is disabled.',
    Disable_skip_msg: 'The Skip feature is disabled or wrong barcode entered.',
    Scanpack_multipack_msg:
      'The multi-pack barcode scanned exceeds the quantity remaining',
    Scan_correct_barocde: 'Please scan items in the suggested order',
    Scanpack_pass_fun_msg: 'The pass function is disabled',
    Scanpack_required_serial_msg:
      "The Value Scanned does not appear to be a Valid serial or lot number. Please check the 'Require Serial/Lot Prefix' setting in your scan and pack options.",
    Scanpack_barcode_found_or_special_code_msg:
      'The value entered matches a user confirmation code, one of the action barcodes or a product barcode. Please contact support if this value is required.',
    Scanpack_Item_Shipment: 'Shipment not found!',
    Scanpack_Item_Typecount_exceed_error:
      'The quantity entered exceeds the remaining number of pieces in the order. Please try again',
    addBar_text: 'Carefully check the item before scanning.',
    restartAlert:
      'Are you sure you would like to reset all scanning progress for this order?',
    service_Issue_msg: 'Service Issue Order',
    service_Issue_msg_one:
      'This order’s status is “Service Issue”. To change the status to “Awaiting” so it can be scanned please scan or enter your confirmation code. If you would like to scan a different order you can scan another order number to continue.',
    service_Issue_msg_two:
      'This order’s status is “Service Issue”. To change the status to “Awaiting” so it can be scanned please ask a team member with Order Edit permissions to scan or enter their confirmation code. If you would like to scan a different order you can scan another order number to continue.',
    service_Issue_notification: 'The confirmation code entered is not valid',
    service_Issue_notification_one:
      'The confirmation code entered is not associated with a user that has order edit permission.',
    refreshTooltip:
      'The import button will start an order import from all active order sources on the account. You can check the status of an import in progress using the refresh button.',
    addBarText: 'It will be saved and also marked scanned.',
    trackingSubmit: `The tracking number on the shipping label you just scanned does not match the tracking number imported with that order. Please verify that you have the correct shipping label and try your scan again. If you are aware of the issue and would like to proceed with the next order please scan your confirmation code to continue.`,
    moreToScanLabel: 'more to scan',
    outOfStockLabel: 'Report Out of Stock',
    scannedLabel: 'Scanned',
    typeInCountLabel: 'Type-In Count'
  },
  nextItems: {
    passButtonLabel: 'Pass',
    scanAllLabel: 'SCAN\nALL',
    clickScanLabel: 'Click Here Before Scanning',
    tabHereLabel: 'Tap Here Before Scanning',
    readyForScanLabel: 'Tap Here Before Scanning',
    gotItLabel: 'Got it.',
    noOrderToScan: 'No order is present to scan',
    noItemsToScan: 'No more item remaining to scan',
    restartButton: {
      firstLabel: 'Restart',
      secondLabel: 'Order'
    },
    reloadButton: 'Reload',
    addNoteButton: {
      firstLabel: 'Add',
      secondLabel: 'Note'
    },
    activityLogLabel: 'Activity Logs',
    tryAgainLabel: 'Please try again',
    barcodeScanned: 'The barcode you have scanned',
    doesNotMatch:
      'does not match the tracking number imported with this order.',
    addBarCodeLabel: 'ADD A BARCODE',
    changesSaveLabel: 'Changes Successfully Saved',
    youLabel: 'You',
    leaderLabel: 'Leader',
    youAreInLead: 'You are in the lead ',
    unScannedLabel: 'Unscanned Items',
    scannedLabel: 'Scanned Items',
    unScannedLabelMobile: 'Un-scanned',
    scannedLabelMobile: 'Scanned'
  },
  popupTracking: {
    noTracingFound: 'Unable to Verify Shipping Label',
    noUser_permission_text_one:
      'To continue you can scan a shipping label with a tracking number barcode that matches the tracking number associated with the current order, or you can override this verification by entering a confirmation code with Order Edit Permissions.',
    noUser_permission_text_two:
      'If neither of these options are available to you, you can close this notification and return to this order later to verify the shipping label. The order will be displayed in the Awaiting list until the shipping label is verified.',
    header: 'Please try again'
  },
  recordTracingmsg: {
    createShippingLabel:
      'Please create the shipping label, adhere it to the package, and scan the tracking number to continue.'
  },
  shippinglabelmsg: {
    scanShippingLabel: 'Please scan the shipping label barcode to continue.',
    noRecordTracking:
      'This order does not have a tracking number associated with it. It may have been imported before the shipping label was generated. Please click or press Enter to bypass this check and mark the order scanned.'
  },
  orderConfirmationPopup: {
    orderConfirmationMismatch:
      "This confirmation code does not appear to valid. Confitmation codes can be updated on the user's page in the setting section.",
    orderConfirmationText: 'Confirmation is required for this note:',
    orderConfimationEnterScan:
      'Enter or scan your confirmation code to continue.',
    orderConfirmationTextBedoreInput:
      'Please confirm you saw the note by entering your confirmation code.'
  },
  productConfirmationPopup: {
    productConfirmationTest:
      'Please confirm the following product instructions were followed by entering your confirmation code.',
    productConfirmationEnterScan:
      'Enter or scan your confirmation code to continue',
    productConfirmationAlert:
      "This confirmation code does not appear to valid. Confitmation codes can be updated on the user's page in the setting section.",
    inputPlaceHolder: 'Enter Confirmation Code to Continue',
    productInstruction: 'Product Instruction for'
  },
  serialRecord: {
    serial_number: 'Scan or Enter Serial, Lot or Batch Number',
    serial_number_two: 'Scan or Enter Serial, Lot or Batch Number 2'
  },
  scanPack: {
    already_scanned: 'The order has already been scanned.',
    already_cancelled: 'This order has been canceled and can not be scanned.',
    permissionDeniedTitle: 'Permission Denied',
    permissionDeniedMessage:
      'Administrative users don’t have permission to scan orders.',
    scanned_one_pass: ' The order has been marked scanned.',
    packing_slip: 'Scan a Packing Slip',
    shippingLable_or_packingSlip: 'Scan a Packing Slip or Shipping Label',
    shippingLable: 'Scan a Shipping Label',
    assignOrderTooltip:
      'This is a dummy tooltip. You can add additional information here for users.',
    empty_order:
      'No items were found in this order. If this is not expected please try removing and reimporting the order. If this issue continues please contact support.',
    quickScanTollTip: `Click the Quick-Scan button or scan a barcode with the
                      value QUICKSCAN to automatically display the oldest
                      unscanned order for verification.`,
    noOrderPresent: `No orders were found in the awaiting orders list.`,
    alreadyScanned: `This order has already been scanned`,
    howToProceed: `How would you like to proceed`,
    rePrint: `Re-Print Shipping Label`,
    reScan: `Re-Scan This Order`,
    alreadyScannedMessage: `Order {{orderNumber}} has already been scanned.`,
    inputPlaceHolder: 'Ready for Order Scan',
    scanLabel: 'Scan',
    quickScanLabel: 'Quick-Scan',
    importLabel: 'Import Now',
    importStatusLabel: 'Check Import Status',
    rfoTitle: `Scan the Packing Slip of an Order you'd like to Pack.`,
    checkInternet: 'Please check your internet connection',
    importStatus: {
      status: 'Import Status',
      last: 'Last',
      current: 'Current',
      processed: 'Orders Processed',
      remaining: 'Orders Remaining',
      elapsedTime: 'Elapsed Time',
      timeRemaining: 'Time Remaining',
      lastImport: 'Last import completed'
    },
    shouldInventoryAdjust: 'Should inventory be adjusted?',
    noReScanItems: 'No, the previous items are being re-scanned',
    yesPullInventory: 'Yes, new items were pulled from inventory',
    noDataAvailable: 'No new or updated orders were found in the last import',
    lastImportLabel: 'The last import was',
    importStartedLabel: 'The last import was started',
    ago: 'ago',
    estimatedTime: 'Estimated time remaining',
    hoursForStore: 'seconds for store',
    minutesForStore: 'minutes for store',
    secondsForStore: 'seconds for store',
    calculateEstimate: 'Estimated time remaining calculating',
    hours: 'hours',
    minutes: 'minutes',
    headerLabel: 'Scan & Verify'
  },
  product: {
    images_remove:
      'Are you sure you want to remove this image from the product?',
    alias_of_another: 'Make this product an alias of another',
    aliases_of_this_product: 'Choose products that are aliases of this product',
    kit_to_product: 'Change this kit back to a product',
    product_to_kit: 'Change this product to a kit',
    product_not_found: 'Product cannot be found with sku',
    same_barcode_found: 'Oh shizzle, that barcode already exists!',
    same_sku_found: 'Oh shizzle, that SKU already exists!',
    QOH: 'QOH',
    location1: 'Location 1',
    location2: 'Location 2',
    location3: 'Location 3',
    location_qty1: 'Location 1 Qty',
    location_qty2: 'Location 2 Qty',
    location_qty3: 'Location 3 Qty',
    inventory_alert_level: 'Inventory Alert Level',
    inventory_target_level: 'Inventory Target Level',
    product_name: 'Product Name',
    skus: 'SKUs',
    barcodes: 'Barcodes',
    unique_bar: 'Scan or Enter a unique Barcode',
    unique_sku: 'Scan or Enter a unique SKU',
    scan_qty: 'Scan Qty',
    categories: 'Categories',
    fnsku: 'FNSKU',
    asin: 'ASIN',
    isbn: 'ISBN',
    ean: 'EAN',
    supplier_sku: 'Supplier SKU',
    avg_cost: 'AVG Cost',
    count_group: 'Count Group',
    product_weight: 'Product Weight',
    // scan & pack option text
    click_scan: 'Pass / click-scan',
    type_count: 'Type-In Count',
    record_serial: 'Record Serial / Lot / Batch',
    intangible_item: 'Intangible Item',
    add_any_order: 'Add to any order',
    optional_item: 'Optional Item / skippable',
    packing_ins: 'Packing Instructions',
    require_conf: 'Require Confirmation',
    scanning_seq: 'Scanning Sequence Override',
    custom_prod1: 'Custom Product 1',
    custom_prod2: 'Custom Product 2',
    custom_prod3: 'Custom Product 3',
    display: 'Display',
    aliasOfAnotherMessage: 'Are you sure? This can not be undone!',
    click_scan_tooltip:
      'Items with missing or damaged barcodes can be Passed using the Pass button when enabled. The option can be disabled for items that should never be passed. "With Confirmation" will require a user with "Pass Button" permission to provide their confirmation code. "With Message" will permit Passing but a message will be shown which discourages unnecessary use.',
    type_count_tooltip:
      "When enabled, this setting allows you to scan one of an item and then manually type in a quantity rather than scanning each item one by one. It's ideal when you have many items that are pre-counted in cases. You can set the type-in trigger in the Scan and Pack settings.",
    record_serial_tooltip:
      'When enabled, the packer will be prompted to scan a serial number each time this product is packed. The recorded serial can then be viewed in the Order Activity log or downloaded in the Order Serial CSV. One or two entries can be recorded based on the setting.',
    intangible_item_tooltip:
      "Intangible items, also known as transactional items, are non-physical items that do not need to be packed. Examples are Coupon Codes, Insurance, Same day handling charges etc. Many times these items import along with regular order items. To avoid having these items prompted during Scan and Pack you can enable this option to designate them as intangible.If you have intangible items that are dynamically generated but always contain a specific string you can have GroovePacker automatically set these items to intangible. You'll find this option in the Scan and Pack settings.",
    add_any_order_tooltip:
      "When this option is enabled for a product you can add it to any order you're packing just by scanning it. This is great for tracking the inventory of boxes or bags that are decided on at the time of packing. Catalogs, coupons, freebie items can be scanned and tracked too. Items added will be saved with the order record in GroovePacker and included on packing slips printed after packing is complete.",
    optional_item_tooltip:
      'Unlike the commonly used "Pass" option the Skippable option is usually enabled only for non-essential items, like free-bees or coupon cards which are normally scanned but which can be skipped when stock is out. The SKIP action barcdoe is scanned to skip an item. Skipped items may be removed from the order or may remain depending on the global setting found in the Scan and Pack options with the SKIP action barcode.',
    packing_ins_tooltip:
      'The packing instructions text here will be shown every time this item is suggested for scanning. In addition to adding instructions here they can also be imported in bulk using the CSV product importer. Enabling the Confirmation Required switch will prompt the user to scan or enter their assigned confirmation code to confirm they have seen the product instructions',
    scanning_seq_tooltip:
      'The suggested scanning sequence is determined automatically. Items are sorted alphabetically by the Primary bin location. If no primary location is saved the SKU will be used to sort the items. If you wish to Override this sorting sequence and force some items to display before or after other items this setting can be used. ie. If the item should be picked or scanned before all others, or after all others you can adjust this value. By default, all items have a value of 50. Items with override values closer to 1 will be sorted before those with values closer to 99 regardless of the SKU or Location saved. The override value can also be set in bulk using a CSV file.',
    custom_prod1_tooltip:
      'Custom product data can be entered here or imported using the product CSV importer. Each field can optionally be displayed when the item is suggested for scanning. The is very useful when variant information like size or color is not easily readable in the SKU or product name but is required by the picker or packer.',
    scanRecordCountText:
      'Confirmation is required for this action.\nPlease scan or enter your confirmation code to continue.',
    scanRecordErrorText:
      'The confirmation code provided is not valid. Please try again',
    zeroBarcodeText:
      '”New” items will automatically change to “Active” items when their barcode is added. It’s not possible to manually change the status from “New” to “Active”. In the case of Kits, each kit will need to have active part items as well as a barcode to be active.',
    address_new_items: 'How to address "New" items',
    // Kit options text
    kit_scanning: 'Kit Scanning Options',
    alertBoxMultiBox:
      'Please scan items into the current box before creating another box.',
    alertBoxPermission:
      'Please contact a team member and request edit permissions before making changes.',
    alertBoxSaveData: 'product data has been updated',
    status: 'Status',
    prePackedKitItem: 'Pre-packed kits are scanned as one item',
    oneBarcodeKitItem: `- One barcode is scanned which represents the all kit items. This permits inventory tracking for the kit parts.`,
    kitParts: 'Kit parts items are each scanned individually',
    singleKitItem: `- The barcodes of the individual items in the kit are always scanned.`,
    singleKitBarcode: `- A single kit barcdoe is scanned unless prepacked kits are unavailable and kit part are barcodes will need to be scanned.`,
    kitPrePacked: `Kits are usually Pre-packed`,
    pickerSelectedOptions: {
      one: `1 - Pre-Packed Kits Are Scanned as One item`,
      two: `2 - Kit Part items are each scanned individually`,
      three: `3 - Kits are usually Pre-packed`
    }
  },
  directprinting: {
    selectprinter:
      'Please choose the printer that you would like to use for jobs of this type.',
    directprintingHead: 'Direct Printing Settings',
    otherOptions: 'Other Options',
    packingslipSetting: 'Packing Slips & Print Jobs',
    productBarcode: 'Order Number Barcode',
    shippiglabel: 'Shipping Labels via API',
    ordernumberbarcode: 'Product Barcode Labels',
    hapticsLable: 'Haptic Feedback',
    receiveRecountLabel: 'Product Receiving Labels',
    alertForPdfSizeEdit:
      'Input is not editable on GPX. You can edit the Pdf size from legacy app',
    packingsliptolltipOne:
      'Direct printing uses an app installed on your Mac or PC to send print jobs directly to your printer instead of opening them in a browser tab.',
    packingsliptolltipLinkText: 'This knowledge base article',
    printerSizeSelectionText:
      'Please select the print size and then choose a file.',
    packingsliptolltipTwo:
      ' explains in detail. Each user can set a printer for each job type that will be used when they print. The Reset button can be used to clear all printer associations.',
    productbarcodetolltip:
      'Product barcode labels can be printed from a number of areas in the GPX app. When enabled they will be sent directly to the printer.',
    shippinglabeltolltip:
      'GroovePacker and generate shipping labels using the Label API of some order managers. When enabled these labels will be sent directly to the printer.',
    ordernumbertolltip:
      'A smalll label containing just the customer’s name and order number can be printed automatically after each order is scanned. When enabled this label will be sent directly to the printer. ',
    tooltippacking:
      ' Packing slips can be printed manually or automatically. Enable this switch to have packing slips sent directly to the printer.',
    tooltipBarcode:
      'Product barcode labels can be printed from a number of areas in the GPX app. When enabled they will be sent directly to the printer.',
    tooltipShipping:
      'GroovePacker can generate shipping labels using the Label API of some order managers. When enabled these labels will be sent directly to the printer.',
    tooltipOrderBarcode:
      'A smalll label containing just the customer’s name and order number can be printed automatically after each order is scanned. When enabled this label will be sent directly to the printer. ',
    tooltipHaptics:
      'When enabled, Android devices will vibrate to confirm scans and some operations.',
    tooltipDirectPrintinHeading:
      'Direct printing uses an app installed on your Mac or PC to send print jobs directly to your printer instead of opening them in a browser tab. This knowledge base article explains in detail. Each user can set a printer for each job type that will be used when they print. The Reset button can be used to clear all printer associations.',
    tooltipReceivingPrinting:
      'Product receiving labels are used to label boxes of extra stock so they can be easily identified and scanned. This switch enables the direct print option for these labels',
    tooltipWeights:
      'You can enter weights in ether Metric or English units. For English, the weight can be entered using a combination of Pounds and Ounces or just Ounces. For Metric, the weight can be entered using a combination of Kilograms and Grams or just Grams.',
    tooltipResidential:
      'UPS requires that a shipment be designated Residential if it is shipped to a residential address or Commercial if it is shipped to a commercial address.',
    alertForPdfLimit:
      'You have reached the daily limit of file picks. Or file size exceed 40MB',
    alertForNoPrinter:
      'No printers available. To print from the browser please disable direct print.',
    confirmAgainPrinting:
      'Are you sure? \n It looks like this file may have already been printed.',
    pdfMovedNotification:
      'After printing, the PDF will be moved to the printed tab.',
    // printComplete:"File print using QZ",
    selectLanguage: 'Select Language',
    english: 'English',
    spanish: 'Spanish'
  },
  clearFilterPrompt: {
    clearFiltersText: 'No Order in the current view matches your search.',
    clearFiltersBtnText:
      'Click here to clear filters and expand your search to all orders in GroovePacker.'
  },
  packingCamDetail: {
    enterEmail:
      'Please add an email address below to enable the packing cam options.',
    placeholder: 'Enter Email Address',
    emailSentPopup: 'Email sent Successfully',
    failToUpload: 'Failed to upload image, try again',
    uploadComplete: 'Upload complete',
    uploading: 'Uploading...',
    escTollTip: 'ESC to Cancel',
    spaceBarTollTip: 'SPACEBAR to Retake',
    enterTollTip: 'ENTER to Confirm',
    cancelLabel: 'Cancel',
    retakeLabel: 'Retake',
    confirmLabel: 'Confirm'
  },
  reprintText: {
    reprintNotAllowHeading:
      'They were purchased in the order manager and would need to be reprinted there.',
    previousPurchaseLabelHeading: 'Previously purchased labels found',
    canBeReprintHeading: 'Previously printed labels can be reprinted below',
    canBeReprintSubHead: 'Click a label to reprint it.'
  },
  order: {
    internal_notes: 'Internal Notes',
    notes_to_packer: 'Notes to Packer',
    customer_comments: 'Customer Comments',
    tags: 'Tags',
    notes_from_packer: 'Notes from Packer',
    internal_notes_tooltip:
      'Internal notes can be imported from the order source. For example if you add a note to an order in ShipStation or ShippingEasy it can be imported and saved in this field. The import of internal notes  is controlled in the settings for each store. Once imported, an option in the scan and pack setting determines if the note will be shown to the packer.',
    notes_to_packer_tooltip:
      'If notes to the packer are being added in GroovePacker directly this field should be used. Anything saved here is always shown during scanning.',
    customer_comments_tooltip:
      'With some integrations customer comments can be imported into this field and optionally displayed during scanning. Once imported, an option in the scan and pack settings determines if the customer comments will be shown to the packer.',
    tags_tooltip:
      'Shopify order tags can be imported into this field and optionally displayed during scanning. Once imported, an option in the scan and pack settings determines if the tags will be shown to the packer.',
    notes_from_packer_tooltip:
      'A note added to the order during the scanning process will be displayed here. The note can be automatically emailed to the notifications address specified in the system settings as well. The note can be added during scanning by using the "Add Note" button or by scanning the action barcode: NOTE This barcode can be printed from the Scan and Pack settings section.',
    order_number: 'Order Number',
    scanning_completed: 'Scanning Completed',
    order_date: 'Order Date',
    tracking_number: 'Tracking Number',
    customer_order_data1: 'Customer Order Data 1',
    customer_order_data2: 'Customer Order Data 2',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    company: 'Company',
    city: 'City',
    state: 'State',
    address_1: 'Address 1',
    address_2: 'Address 2',
    postal_code: 'Postal Code',
    country: 'Country',
    addKitToKitMessage:
      'Would you like to add the kit part items from kit {{childKit}} as part items under {{parentKit}}',
    kitItemTolltip:
      'Search for the kit part items you would like to add by entering the name or sku or by scanning the barcode. Click an item to select it. Multiple searches and selections can be made before saving so you can add multiple items at the same time.',
    inventoryKitTollTip:
      'Pre-packed kits are scanned as one item - One barcode is scanned which represents the all kit items. This permits inventory tracking for the kit parts.Kit parts items are each scanned individually - The barcodes of the individual items in the kit are always scanned.Kits are usually Pre-packed - A single kit barcdoe is scanned unless prepacked kits are unavailable and kit part are barcodes will need to be scanned.',
    productSearchListTooltip:
      'Scan or enter the barcode of an item to open it directly. Searching with the exact SKU will also open the item directly. Partial matches will be shown in a list.',
    addItemToOrderError:
      'Please change the status of this product/kit to Active before adding it to the order.',
    addItemToOrderTollTip:
      "Multiple items can be added to an order at once. After searching for an item by it's name, sku or barcode use the checkbox to select it. Additional selections can be made the same way. Once all required items are selected click 'Save & Close' to add them to the order.",
    multiBoxTollTip:
      'The dropdown allows you to select whether the packing slip will be printed for the currently selected box(in the table below) or if packing slips for all boxes will be printed. Click the printer icon to initiate printing.',
    orderNotFound:
      'The order number provided was not found. The corresponding order may not have been imported yet.',
    selectedUserError:
      'Please select multiple orders for the  assigning or removing.',
    noSelectedUserError:
      'Please select orders or users for assigning or removing.',
    clearAllAssignmentLabel: 'Clear All Assignments',
    clearAllAssignmentMessage: 'Are you sure you want to clear all assignments?'
  },
  tagPriority: {
    createPriorityCardText:
      'Orders with this priority will be identified by the following tag:'
  },
  activityException: {
    alerts: {
      exceptionRecorded: 'Exception Successfully Recorded',
      exceptionMissingReason: 'Cannot record exception without reason',
      exceptionCleared: 'Record exception cleared',
      noExceptionToClear: 'Order does not have exception to clear'
    },
    headers: {
      activityLog: 'Activity Log',
      packingExceptions: 'Packing Exceptions',
      exceptionType: 'Exception Type',
      description: 'Description',
      associatedWith: 'Associated exception with'
    },
    buttons: {
      saveAsText: 'Save as Text',
      recordException: 'Record Exception',
      clearException: 'Clear Exception'
    }
  },
  addItemInBox: {
    addItemsTollTip: `Multiple items can be added to an order at once. After searching for an item by it's name, sku or barcode use the checkbox to select it. Additional selections can be made the same way. Once all required items are selected click "Save & Close" to add them to the order.`
  },
  shipmentRecord: {
    additionalShipments: 'Additional shipments Associated With this order',
    shipmentWarning:
      'One of the shipments associated with this order was scanned in GroovePacker and then combined in ShippingEasy. Rather than modifying an order that has been scanned we are letting you know so you can take any action that is required',
    acknowledgeAndContinue:
      'Once all unwanted shipments are removed you can click here to acknowledge and continue.'
  },
  scanToCart: {
    tollTip: `If orders need to be assigned to a specific tote, they can be tagged with that tote's tote ID. eg. Tagging an order with "A-1-C01" will assign it to the first tote on row A of cart 1.Click a tote below to copy its tote ID tag to your clipboard.`,
    tollTipLabel: `Orders Tagged "{{cartId}}" will only be assigned to this cart.`,
    addRowLabel: `Add a Row`,
    addCartLabel: `Add a Cart`,
    cartsLabel: 'Carts',
    printToteLabel: 'Print Tote Labels',
    rowIdLabel: 'Row ID',
    totesInRowLabel: 'Totes/Row'
  },
  assignOrder: {
    assign: 'Assign',
    orders: 'Orders',
    successMessage: 'orders successfully assigned.',
    notAssignedMessage: 'No orders assigned.'
  },
  dashboard: {
    eachUserItemsByToday: `Each User Item's/min by today`,
    itemsScannedByTime: `Items scanned by this time yesterday vs today`,
    current15MinSession: `Total items by user - Current 15 min session /n 10 min 04 sec Remaining`
  }
};
export default en;
