import React, {Component} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {TouchableOpacity} from 'react-native-web';
import {ImportOrderStatus} from '../../actions/scanpackAction';
import {connect} from 'react-redux';
import HorizontalChart from './components/HorizontalChart';
import DailyChart from './components/DailyChart';
import TodayVsYesterdayChart from './components/TodayVsYesterdayChart';
import WeeklyChart from './components/WeeklyChart';
import WithIn15MinChart from './components/WithIn15MinChart';
import i18n from 'i18n-js';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonList: [
        {value: 1, status: true},
        {value: 2, status: false},
        {value: 3, status: false},
        {value: 4, status: false},
        {value: 5, status: false}
      ],
      activeChart: 1
    };
  }

  handlePress(index, value) {
    let data = this.state.buttonList;
    let list = data.map((button, i) => ({
      ...button,
      status: i === index
    }));
    this.setState({
      buttonList: list,
      activeChart: value
    });
  }

  render() {
    const {buttonList, activeChart} = this.state;

    return (
      <View style={styles.container} onStartShouldSetResponder={() => true}>
        <LinearGradient
          colors={['#000', '#5c778f', '#253c57', '#000']}
          start={[-0.5, -1]}
          style={{flex: 1, paddingBottom: 0}}>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              marginTop: 10
            }}>
            {activeChart == 1 && (
              <>
                <Text style={styles.title}>
                  {i18n.t('dashboard.eachUserItemsByToday')}
                </Text>
                <DailyChart />
              </>
            )}
            {activeChart == 2 && (
              <>
                <Text style={styles.title}>
                  {i18n.t('dashboard.itemsScannedByTime')}
                </Text>
                <TodayVsYesterdayChart />
              </>
            )}
            {activeChart == 3 && (
              <>
                <Text style={styles.title}>
                  {i18n.t('dashboard.eachUserItemsByToday')}
                </Text>
                <WeeklyChart />
              </>
            )}
            {activeChart == 4 && (
              <>
                <Text style={styles.title}>
                  {i18n.t('dashboard.current15MinSession')}
                </Text>
                <WithIn15MinChart />
              </>
            )}
            {activeChart == 5 && (
              <>
                <Text style={styles.title}>
                  {i18n.t('dashboard.eachUserItemsByToday')}
                </Text>
                <HorizontalChart />
              </>
            )}
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 50,
                justifyContent: 'center'
              }}>
              {buttonList.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => this.handlePress(index, item.value)}>
                  <View
                    style={[
                      styles.optionStyle,
                      {
                        backgroundColor:
                          item.status === true ? '#FFFFFF' : 'transparent'
                      }
                    ]}
                  />
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </LinearGradient>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {flex: 1},
  graphStyle: {
    marginTop: 20,
    alignSelf: 'center',
    marginVertical: 8
  },
  title: {
    alignSelf: 'center',
    color: '#FFFFFF',
    fontSize: 20,
    marginBottom: 20
  },
  optionStyle: {
    width: 20,
    height: 20,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 25,
    marginLeft: 10
  }
});

const mapDispatchToProps = {
  ImportOrderStatus
};
export default connect(null, mapDispatchToProps)(Dashboard);
