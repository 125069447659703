import React, {useEffect, useState} from 'react';
import {StyleSheet, Dimensions} from 'react-native';
import {StackedBarChart} from 'react-native-chart-kit';
import {useDispatch, useSelector} from 'react-redux';
import {getStatisticsWeeklyDetails} from '../../../actions/statisticsAction';
const wp = Dimensions.get('window').width;
const hp = Dimensions.get('window').height;

const WeeklyChart = () => {
  const [dataList, setDataList] = useState([]);
  const [legend, setLegend] = useState([]);
  const [labels, setLabels] = useState([]);

  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.list);

  useEffect(() => {
    dispatch(getStatisticsWeeklyDetails());
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [response]);

  console.log('response:', response);

  const getDetails = async () => {
    let statistics = [];
    let userIds = [];
    let labels = [];

    let statisticsList = response.data.statistics;

    for (let i = 0; i < statisticsList.length; i++) {
      const object = statisticsList[i];
      labels.push(moment(object.date, 'ddd, DD MMM YYYY').format('ddd Do'));

      const list = object.data;

      let arr = [];
      let sum = 0;

      for (let j = 0; j < list.length; j++) {
        userIds.push(list[j].packing_user_id);
        let list2 = list[j].data;
        for (let k = 0; k < list2.length; k++) {
          sum = sum + list2[k].scanned_item_count;
        }

        arr.push(sum);
      }

      statistics.push(arr);
    }
    const uniqueUserIds = [...new Set(userIds)];

    setDataList(statistics);
    setLabels(labels);

    let userDetails = response.data.user_details;
    let namesList = [];

    for (let i = 0; i < userDetails.length; i++) {
      for (let j = 0; j < uniqueUserIds.length; j++) {
        if (userDetails[i].id == uniqueUserIds[j]) {
          namesList.push(userDetails[i].name);
        }
      }
    }
    setLegend(namesList);
  };

  const chartConfig = {
    backgroundGradientFrom: '#000000',
    backgroundGradientTo: '#000000',
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    barPercentage: 1.5,
    useShadowColorFromDataset: false,
    propsForLabels: {
      fontSize: (wp * 0.8) / 100,
      fontWeight: 'bold'
    }
  };

  const data = {
    labels: labels,
    data: dataList,
    legend: legend,
    barColors: [
      '#A9151A',
      '#CE7C04',
      '#994407',
      '#B1B91B',
      '#11D003',
      '#00C8A5'
    ]
  };

  return (
    <StackedBarChart
      style={styles.graphStyle}
      data={data}
      width={(wp * 60) / 100}
      height={(hp * 50) / 100}
      chartConfig={chartConfig}
      hideLegend={false}
    />
  );
};

export default WeeklyChart;

const styles = StyleSheet.create({
  graphStyle: {
    marginTop: 20,
    alignSelf: 'center',
    marginVertical: 8
  }
});
