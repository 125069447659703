import React, {Component} from 'react';
import {
  View,
  SafeAreaView,
  Text,
  TextInput,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
  Image,
  Button,
  ScrollView
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from '../../style/drawer';
import stylesAlert from '../../style/scanpack';
import globalStyles from '../../style/global';
import {connect} from 'react-redux';
import * as RootNavigation from '../root_navigation';
import {SetItem, GetItem} from '../../actions/updateAsyncAction';
import {SubmitLog, ScanPackBugReport} from '../../actions/saveLogAction';
import {
  setBugOrSupportPopUP,
  resetBugOrSupportPopUP
} from '../../actions/userAction';
import {CreateProduct} from '../../actions/productAction';
import {CreateOrder} from '../../actions/orderActions';
import LogoutIcon from '../../../assets/logout_icon.png';
import SettingImage from '../../../assets/settingIcon.png';
import CloseDrawerIcon from '../../../assets/close_drawer.png';
import ExIcon from '../../../assets/G_Ex_icon.png';
import BugIcon from '../../../assets/bug_icon.png';
import ScannerIcon from '../../../assets/scanner_icon.png';
import {fontFamily} from '../../helpers/fontFamily';
import {LinearGradient} from 'expo-linear-gradient';
import Constants from 'expo-constants';
import SaveIcon from '../../../assets/save_icon.png';
import {FontAwesome} from '@expo/vector-icons';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import InerternetConnectionAlert from '../internetConnectionAlert';
const windowHeight = Dimensions.get('window').height;
import InterNetChecks from '../internateConnectionCheck';
import CustomScrollBar from '../../commons/customScrollBar';
import axios from 'axios';

let localLogCheck = false;
let updateLogLocal = false;
let reportBug = false;
let reportLogSuccess = false;

class Drawer extends Component {
  constructor(props) {
    // console.log("bvhbvvhbv", props)
    super(props);
    this.state = {
      userInfo: '',
      // data: {
      //   "emailSaveLocal": "",
      //   "name": ""
      // },
      localLogPopUp: false,
      reportBugPopUp: false,
      feedbackPopUp: false,
      logTime: '',
      feedbackField: '',
      feedbackName: '',
      feedbackEmail: '',
      feedbackFieldError: false,
      spinner: false,
      emailSave: false,
      windowWidth: Dimensions.get('window').width,
      popInternet: true
    };
  }
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };
  componentDidMount() {
    // console.log("this===>",this.state.userInfo.username)
    const sI = setInterval(() => {
      this.test();
    }, 2000);
    window.addEventListener('resize', this.updateDimensions);
    this.getUserInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getUserInfo = async () => {
    let info = await AsyncStorage.getItem('userInfo');
    if (info) {
      let userInfo = info && JSON.parse(info);
      this.setState({userInfo});
    }
  };

  redirect(page) {
    RootNavigation.reset(page);
    this.props.closeDrawer();
    if (page === 'ScanPack') {
      this.props.checkFunctionHere();
    }
  }

  logout = async () => {
    try {
      const username = await AsyncStorage.getItem('username');
      const access_token = await AsyncStorage.getItem('access_token');
      // await AsyncStorage.clear();
      const url = await AsyncStorage.getItem('url');

      if (access_token.length > 1) {
        try {
          await axios({
            method: 'post',
            url: `${url}/auth/v1/logout`,
            headers: {Authorization: `Bearer ${access_token}`},
            data: {
              token: access_token
            }
          });
        } catch (e) {}

        AsyncStorage.removeItem('access_token');
      }
      if (username) {
        await AsyncStorage.setItem('username', username);
      }
      await AsyncStorage.setItem('tenent', this.state.userInfo.current_tenant);
      this.redirect('SignIn');
    } catch (e) {
      console.log(e);
    }
  };

  componentDidUpdate() {
    if (
      localLogCheck &&
      this.props &&
      this.props.localLog &&
      this.props.localLog.retriveData &&
      this.props.localLog.retriveData !== this.state.localData
    ) {
      localLogCheck = false;
      let tempLocal = this.props.localLog && this.props.localLog.retriveData;
      if (tempLocal !== ' ') {
        let tempConvert = tempLocal !== ' ' && JSON.parse(tempLocal);
        if (tempConvert && tempConvert.length > 0) {
          this.setState({localLogPopUp: true});
        }
        this.setState({localData: tempLocal});
      }
      // else{
      //     this.discardChanges()
      // }
    } else {
      if (
        localLogCheck &&
        this.props &&
        this.props.localLog &&
        this.props.localLog.retriveData === false
      ) {
        localLogCheck = false;
        this.discardChanges();
      }
    }

    if (updateLogLocal && this.props.updateLog.time !== this.state.logTime) {
      if (
        this.props.updateLog &&
        this.props.updateLog.logs &&
        this.props.updateLog.logs.status === 'OK' &&
        this.props.updateLog.updateLog &&
        this.props.updateLog.time
      ) {
        updateLogLocal = false;
        this.setState({logTime: this.props.updateLog.time});
        RootNavigation.reset('ScanPack', 'refreshButton');
        this.props.closeDrawer();
      }
    }

    if (this.props && this.props.updateReportLog && reportLogSuccess) {
      reportLogSuccess = false;
      this.setState({
        reportBugPopUp: false,
        feedbackField: '',
        spinner: false
      });
      RootNavigation.reset('ScanPack', 'refreshButton');
      this.props.closeDrawer();
    }
  }

  handleCreateProduct = () => {
    this.props.CreateProduct();
    this.redirect('ProductSearch');
  };

  handleCreateOrder = () => {
    this.props.CreateOrder();
    this.redirect('OrderSearch');
  };
  handleSettings = () => {
    this.redirect('Settings');
  };
  handleDashboard = () => {
    this.redirect('Dashboard');
  };
  saveClose = () => {
    if (this.state.reportBugPopUp) {
      this.setState({reportBugPopUp: false});
    }
    if (this.state.feedbackPopUp) {
      this.setState({feedbackPopUp: false});
    }
  };

  clearStore = () => {
    localLogCheck = true;
    this.props.GetItem('logsArray');
  };

  dismissPopUp = () => {
    this.setState({localLogPopUp: false, reportBugPopUp: false});
    // RootNavigation.reset("ScanPack");
    this.props.closeDrawer();
  };

  discardChanges = async () => {
    await AsyncStorage.removeItem('logsArray');
    await AsyncStorage.removeItem('storeOrder');
    // await AsyncStorage.multiRemove(["logsArray"] , ["storeOrder"])
    RootNavigation.reset('ScanPack', 'refreshButton');
    this.props.closeDrawer();
  };

  saveChanges = async () => {
    updateLogLocal = true;
    localLogCheck = true;
    let getItem = await AsyncStorage.getItem('logsArray');
    let convert = getItem && JSON.parse(getItem);
    this.props.SubmitLog(convert);
  };

  reportBug() {
    this.setState({reportBugPopUp: true});
    this.props.setBugOrSupportPopUP();
  }

  setFeedback() {
    this.setState({feedbackPopUp: true});
    this.props.setBugOrSupportPopUP();
  }

  validation = () => {
    let feedback =
      this.state.feedbackField && this.state.feedbackField.trim(' ');
    if (feedback === '') {
      this.setState({feedbackFieldError: true});
    } else {
      return true;
    }
  };

  submitBugReport = async () => {
    let identifier = this.state.reportBugPopUp ? 'bug_report' : 'feedback';
    let logging = await AsyncStorage.getItem('Logging');
    let LoggingArray = JSON.parse(logging);
    let bugObject = {
      feedback: this.state.feedbackField,
      name: this.state.feedbackName,
      email: this.state.feedbackEmail,
      identifier: identifier,
      logs: LoggingArray
    };
    // console.log("feedback-->",bugObject)
    if (this.validation() === true) {
      this.setState({spinner: true});
      this.props.ScanPackBugReport(bugObject);
      reportLogSuccess = true;
    }
  };

  cancelBugReport() {
    this.props.closeDrawer();
  }

  //  onNameSaved = () => {

  //     if (this.state.feedbackEmail = "") {
  //       if (this.state.emailSave) {
  //         console.log("check here")
  //         AsyncStorage.removeItem('email').then(() => {
  //         })
  //         this.setState({ emailSave: false })
  //       }
  //       else {
  //         AsyncStorage.setItem(
  //           'emailSaveHere', this.state.feedbackEmail).then(() => {
  //           });
  //         this.setState({ emailSave: true })
  //       }
  //     }
  //   };
  //Check Internate connectiion Here
  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({popInternet: false});
    } else {
      this.setState({popInternet: true});
    }
  };
  render() {
    const {navigation} = this.props;
    let firstV = '';
    let remainV = '';
    if (typeof this.state.userInfo.username == 'string') {
      firstV = this.state.userInfo.username.substring(0, 1);
      remainV = this.state.userInfo.username.substring(1);
    }
    return (
      <React.Fragment>
        {this.state.reportBugPopUp === true || this.state.feedbackPopUp ? (
          <SafeAreaView style={[stylesAlert.alertContainer, {height: '100%'}]}>
            {this.state.spinner ? (
              <View style={{flex: 1, justifyContent: 'center'}}>
                <ActivityIndicator size="large" color="#fff" />
              </View>
            ) : (
              <View
                testID="reportContainer"
                style={{
                  width: this.state.windowWidth >= 900 ? '50%' : '90%',
                  backgroundColor: '#000',
                  alignItems: 'center'
                }}>
                <View style={{width: '90%'}}>
                  <View style={{alignItems: 'center', marginTop: 10}}>
                    <Text
                      style={{
                        fontFamily: fontFamily.font700,
                        fontSize: 30,
                        color: '#637d94'
                      }}>
                      {this.state.reportBugPopUp
                        ? 'Report a Bug'
                        : 'Get Support'}
                    </Text>
                    <View
                      style={{
                        alignSelf: 'flex-end',
                        justifyContent: 'center',
                        alignItems: 'center',

                        flexDirection: 'row'
                        // borderWidth: 1
                      }}>
                      <TouchableOpacity
                        testID="cancelButton"
                        style={{
                          marginRight: 10,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: '#fff',
                          borderRadius: 30,
                          justifyContent: 'center',
                          alignItems: 'center',
                          // backgroundColor: "#336599",
                          height: 30,
                          shadowColor: '#2bfac3',
                          shadowOffset: {width: 0, height: 0.5},
                          shadowRadius: 8,
                          elevation: 5,
                          shadowOpacity: 0.5
                          // box-shadow: rgb(43 250 195) 0px 1px 3px;
                        }}
                        onPress={() => {
                          this.saveClose();
                          this.props.resetBugOrSupportPopUP();
                        }}>
                        <Text
                          style={{
                            color: 'white',
                            paddingHorizontal: 10,
                            fontWeight: 'bold'
                          }}>
                          cancel
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID="closeButton"
                        onPress={() => {
                          this.saveClose();
                          this.props.resetBugOrSupportPopUP();
                        }}
                        style={{
                          marginRight: 10,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: '#fff',
                          borderRadius: 30,
                          justifyContent: 'center',
                          alignItems: 'center',
                          // backgroundColor: "#494848",
                          height: 30,
                          shadowColor: '#e853fa',
                          shadowOffset: {width: 0, height: 0.5},
                          shadowRadius: 8,
                          elevation: 5,
                          shadowOpacity: 0.5
                        }}>
                        <Text
                          style={{
                            color: 'white',
                            paddingHorizontal: 10,
                            fontWeight: 'bold'
                          }}>
                          close
                        </Text>
                      </TouchableOpacity>
                    </View>

                    {this.state.reportBugPopUp ? (
                      <i
                        className="icon-bug"
                        style={{
                          color: '#778fa5',
                          marginLeft: -8,
                          fontSize: '60px'
                        }}></i>
                    ) : (
                      // <Image source={BugIcon} style={{ width: 50, height: 50 }} resizeMode="contain" />
                      // <Image source={SupportIcon} style={{ width: 50, height: 50 }} resizeMode="contain" />
                      <i
                        className="icon-lifebuoy"
                        style={{
                          color: '#778fa5',
                          marginLeft: -8,
                          fontSize: '60px'
                        }}></i>
                    )}
                  </View>
                  <Text
                    style={{
                      fontFamily: fontFamily.font400,
                      fontSize: 15,
                      color: '#fff',
                      textAlign: 'left',
                      marginTop: 10
                    }}>
                    {this.state.reportBugPopUp
                      ? 'Let our dev team know about a bug you’ve encountered. A log of the most recent activity will be included with your report. Please provide the steps we can take to reproduce the bug if possible.'
                      : "We're here to help! If you're contacting regarding a bug or an error you've just experienced you can use the bug report option which will include logs that may help us resolve the issue."}
                  </Text>
                  <View style={{flexDirection: 'row', marginTop: 10}}>
                    {/* <View style={{ flexDirection: "column", alignItems: "center", marginTop: 26, marginRight: 15 }}>
                      <Image source={SaveIcon} style={{ width: 30, height: 30 }} resizeMode="contain" />
                      <Text style={{ fontSize: 10, fontFamily: fontFamily.font400, color: "#637d94", textTransform: "uppercase" }}>saved</Text>
                    </View> */}

                    <View style={{flexDirection: 'column', width: '98%'}}>
                      {this.state.reportBugPopUp ? (
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: fontFamily.font400,
                            color: '#637d94',
                            fontWeight: 'bold'
                          }}>
                          Name
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: fontFamily.font400,
                            color: '#637d94',
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}>
                          Name
                        </Text>
                      )}
                      <TextInput
                        onChangeText={text => {
                          this.setState({
                            feedbackName: text,
                            feedbackFieldError: false
                          });
                        }}
                        style={{
                          borderWidth: 3,
                          borderColor: '#657b8f',
                          outline: 'none',
                          borderRadius: 5,
                          fontFamily: fontFamily.font400,
                          fontSize: 20,
                          color: '#fff',
                          backgroundColor: '#191919',
                          paddingLeft: 10,
                          paddingVertical: 5,
                          width: this.state.windowWidth >= 900 ? '100%' : '99%'
                        }}
                      />
                    </View>
                  </View>
                  <View style={{flexDirection: 'row', marginTop: 10}}>
                    {/* <View style={{ flexDirection: "column", alignItems: "center", marginTop: 26, marginRight: 15 }}
                    >
                      <TouchableOpacity onPress={() => this.onNameSaved()}>
                      <Image source={SaveIcon} style={{ width: 30, height: 30 }} resizeMode="contain" /></TouchableOpacity>
                      <Text style={{ fontSize: 10, fontFamily: fontFamily.font400, color: "#637d94", textTransform: "uppercase" }}>{this.state.emailSave ? 'SAVED' : 'SAVE'}</Text>
                    </View> */}
                    <View style={{flexDirection: 'column', width: '98%'}}>
                      {this.state.reportBugPopUp ? (
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: fontFamily.font400,
                            color: '#637d94',
                            fontWeight: 'bold'
                          }}>
                          Email
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: fontFamily.font400,
                            color: '#637d94',
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}>
                          Email
                        </Text>
                      )}
                      <TextInput
                        onChangeText={text => {
                          this.setState({
                            feedbackEmail: text,
                            feedbackFieldError: false
                          });
                        }}
                        style={{
                          borderWidth: 3,
                          borderColor: '#657b8f',
                          outline: 'none',
                          borderRadius: 5,
                          fontFamily: fontFamily.font400,
                          fontSize: 20,
                          color: '#fff',
                          backgroundColor: '#191919',
                          paddingLeft: 10,
                          paddingVertical: 5,
                          width: this.state.windowWidth >= 900 ? '100%' : '98%'
                        }}
                      />
                    </View>
                  </View>
                  <View style={{marginTop: 10}}>
                    <TextInput
                      multiline={true}
                      numberOfLines={6}
                      autoCapitalize="none"
                      onChangeText={text => {
                        this.setState({
                          feedbackField: text,
                          feedbackFieldError: false
                        });
                      }}
                      style={{
                        borderWidth: 3,
                        borderColor: '#637d94',
                        outline: 'none',
                        borderRadius: 5,
                        fontFamily: fontFamily.font400,
                        fontSize: 20,
                        color: '#fff',
                        backgroundColor: '#191919',
                        paddingLeft: 10,
                        paddingVertical: 5,
                        width: this.state.windowWidth >= 900 ? '105%' : '113%',
                        marginLeft:
                          this.state.windowWidth >= 900 ? '-3%' : '-6%'
                      }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      alignItems: 'center',
                      paddingBottom: 10
                    }}>
                    <TouchableOpacity
                      testID="submitBugReport"
                      onPress={() => this.submitBugReport()}
                      style={{width: '60%'}}>
                      <LinearGradient
                        colors={['#4b657d', '#768ea5']}
                        locations={[0, 1]}
                        style={{
                          borderRadius: 4,
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingVertical: 5
                        }}>
                        <Text
                          style={{
                            fontFamily: fontFamily.font400,
                            fontSize: 20,
                            color: '#aad3fd',
                            textTransform: 'uppercase'
                          }}>
                          {this.state.reportBugPopUp ? 'Send' : 'Send'}
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}
          </SafeAreaView>
        ) : (
          <React.Fragment>
            {this.state.localLogPopUp ? (
              <SafeAreaView
                style={[stylesAlert.alertContainer, {height: windowHeight}]}>
                <View style={stylesAlert.alertBox}>
                  <View style={globalStyles.flexDirectionRow}>
                    <Text style={stylesAlert.alertText}></Text>
                    <TouchableOpacity onPress={() => this.dismissPopUp()}>
                      <Text style={stylesAlert.alertClose}>X</Text>
                    </TouchableOpacity>
                  </View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20
                    }}>
                    Are you want to save changes ?
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 50
                    }}>
                    <TouchableOpacity
                      style={[
                        stylesAlert.alertSubmitBox,
                        {marginRight: 5, marginLeft: 5}
                      ]}
                      onPress={() => this.saveChanges()}>
                      <Text style={stylesAlert.alertSubmitBtn}>
                        Save Changes
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={[
                        stylesAlert.alertSubmitBox,
                        {marginRight: 5, marginLeft: 5}
                      ]}
                      onPress={() => this.discardChanges()}>
                      <Text style={stylesAlert.alertSubmitBtn}>
                        Discard Changes
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </SafeAreaView>
            ) : (
              <SafeAreaView
                style={[styles.drawerBackView, globalStyles.positionFixed]}>
                <View
                  style={[
                    styles.drawerMainView,
                    {
                      position: 'absolute',
                      width: this.state.windowWidth >= 900 ? '112%' : '226%',
                      height: '100%'
                    }
                  ]}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingHorizontal: 10
                    }}>
                    <TouchableOpacity
                      testID="logout"
                      onPress={() => {
                        this.state.popInternet === true && this.logout();
                      }}
                      style={{alignItems: 'center'}}>
                      <Text
                        style={{
                          fontFamily: fontFamily.font600,
                          fontSize: 14,
                          background:
                            ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                          webkitBackgroundClip: 'text',
                          webkitTextFillColor: 'transparent'
                        }}>
                        Log Out
                      </Text>
                      <View style={{width: 30, height: 30}}>
                        <i
                          className="icon-logout"
                          style={{
                            fontSize: '35px',
                            marginLeft: -10,
                            transform: 'rotate(180deg)',
                            background:
                              ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                            webkitBackgroundClip: 'text',
                            webkitTextFillColor: 'transparent'
                          }}></i>
                        {/* <Image
                          source={LogoutIcon}
                          style={{ width: "100%", height: "100%" }}
                          resizeMode="contain"
                        /> */}
                      </View>
                    </TouchableOpacity>
                    <View style={{alignItems: 'center'}}>
                      <View
                        style={{
                          width: 80,
                          height: 80,
                          borderWidth: 2,
                          borderColor: '#778fa5',
                          borderRadius: 10,
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        {this.state.userInfo.current_tenant && (
                          <Text
                            style={{
                              fontFamily: fontFamily.font700,
                              fontSize: 70,
                              fontStyle: 'italic',
                              marginLeft: '-15%',
                              paddingRight: '15px',
                              marginRight: '-15px',
                              textTransform: 'uppercase',
                              background:
                                ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                              webkitBackgroundClip: 'text',
                              webkitTextFillColor: 'transparent'
                            }}>
                            {firstV}
                          </Text>
                        )}
                      </View>
                      <Text
                        style={{
                          fontFamily: fontFamily.font700,
                          fontSize: 16,
                          background:
                            ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                          webkitBackgroundClip: 'text',
                          webkitTextFillColor: 'transparent'
                        }}>
                        <Text
                          style={{
                            fontFamily: fontFamily.font700,
                            fontSize: 16,
                            background:
                              ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                            webkitBackgroundClip: 'text',
                            webkitTextFillColor: 'transparent'
                          }}>
                          {firstV}
                        </Text>
                        {remainV}
                      </Text>
                      <Text
                        style={{
                          fontFamily: fontFamily.font400,
                          fontSize: 16,
                          background:
                            ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                          webkitBackgroundClip: 'text',
                          webkitTextFillColor: 'transparent'
                        }}>
                        {this.state.userInfo.current_tenant}
                      </Text>
                    </View>
                    <View>
                      <TouchableOpacity
                        testID="dismissPopup"
                        onPress={() => {
                          this.state.popInternet === true &&
                            this.dismissPopUp();
                        }}
                        style={{width: 30, height: 30}}>
                        <Image
                          source={CloseDrawerIcon}
                          style={{width: '100%', height: '100%'}}
                          resizeMode="contain"
                        />
                      </TouchableOpacity>
                    </View>
                    {/* brand */}
                  </View>
                  <CustomScrollBar
                    contentContainerStyle={{
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%'
                    }}>
                    <View style={{marginBottom: '3rem'}}>
                      <LinearGradient
                        start={{x: 0, y: 1}}
                        end={{x: 0, y: 0}}
                        locations={[0, 1]}
                        colors={['#577189', '#526c84']}
                        style={{
                          height: 1,
                          width: '70%',
                          backgroundColor: '#778fa5',
                          marginTop: 50
                        }}
                      />
                      <TouchableOpacity
                        testID="redirectToScanPack"
                        onPress={() => {
                          {
                            this.state.popInternet === true &&
                              this.redirect('ScanPack');
                          }
                        }}
                        style={{
                          marginTop: 50,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-scanner-icon"
                            style={styles.iconsCommon}></i>
                        </View>
                        <Text style={styles.iconText}>Scan & Verify</Text>
                      </TouchableOpacity>

                      <View
                        style={{
                          marginTop: 20,
                          paddingHorizontal: 10,
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}>
                        <View style={{flexDirection: 'row'}}>
                          <View style={{width: 30, height: 30}}>
                            <FontAwesome
                              name="tags"
                              size={24}
                              style={{
                                color: '#768ea5'
                              }}
                            />
                          </View>
                          <Text style={styles.iconText}>Product</Text>
                        </View>
                        <TouchableOpacity
                          testID="redirectToProductSearch"
                          onPress={() => {
                            this.state.popInternet === true &&
                              this.redirect('ProductSearch');
                          }}
                          style={{
                            flexDirection: 'row',
                            marginLeft: 15,
                            marginTop: 2
                          }}>
                          <i
                            className="icon-search"
                            style={styles.iconCommonSub}></i>
                          <Text style={styles.iconTextSub}>Search</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          testID="createProduct"
                          onPress={() => {
                            this.state.popInternet === true &&
                              this.handleCreateProduct();
                          }}
                          style={{
                            flexDirection: 'row',
                            marginLeft: 15,
                            marginTop: 5
                          }}>
                          <i
                            className="icon-magic"
                            style={styles.iconCommonSub}></i>
                          <Text style={styles.iconTextSub}>Create</Text>
                        </TouchableOpacity>
                      </View>

                      <View
                        style={{
                          marginTop: 10,
                          paddingHorizontal: 10,
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}>
                        <TouchableOpacity
                          style={{flexDirection: 'row'}}
                          testID="allOrders"
                          onPress={() => {
                            this.state.popInternet === true &&
                              this.redirect('Orders');
                          }}>
                          <MaterialCommunityIcons
                            name="note-text"
                            size={24}
                            style={{
                              background:
                                ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                              webkitBackgroundClip: 'text',
                              webkitTextFillColor: 'transparent'
                            }}
                          />
                          <Text style={styles.iconText}>Orders</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          testID="redirectToOrderSearch"
                          onPress={() => {
                            this.state.popInternet === true &&
                              this.redirect('OrderSearch');
                          }}
                          style={{
                            flexDirection: 'row',
                            marginLeft: 15,
                            marginTop: 5
                          }}>
                          <i
                            className="icon-search"
                            style={styles.iconCommonSub}></i>
                          <Text style={styles.iconTextSub}>Search</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          testID="createOrder"
                          onPress={() => {
                            this.state.popInternet === true &&
                              this.handleCreateOrder();
                          }}
                          style={{
                            flexDirection: 'row',
                            marginLeft: 15,
                            marginTop: 5
                          }}>
                          <i
                            className="icon-magic"
                            style={styles.iconCommonSub}></i>
                          <Text style={styles.iconTextSub}>Create</Text>
                        </TouchableOpacity>
                      </View>
                      <TouchableOpacity
                        testID="redirectToScanReceive"
                        onPress={() => {
                          this.state.popInternet === true &&
                            this.redirect('ScanReceive');
                        }}
                        style={{
                          marginTop: 10,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-receive"
                            style={{
                              fontSize: '20px',
                              color: '#768ea5'
                            }}
                          />
                        </View>
                        <Text style={styles.iconText}>Receive</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID="redirectToScanRecount"
                        onPress={() => {
                          this.state.popInternet === true &&
                            this.redirect('ScanRecount');
                        }}
                        style={{
                          marginTop: 20,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-recount"
                            style={{
                              fontSize: '20px',
                              color: '#768ea5'
                            }}
                          />
                        </View>
                        <Text style={styles.iconText}>Recount</Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        testID="redirectToPrint"
                        onPress={() => {
                          this.state.popInternet === true &&
                            this.redirect('PrintJob');
                        }}
                        style={{
                          marginTop: 10,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-print"
                            style={{
                              fontSize: '20px',
                              color: '#768ea5'
                            }}
                          />
                        </View>
                        <Text style={styles.iconText}>Print Jobs</Text>
                      </TouchableOpacity>
                      <LinearGradient
                        start={{x: 0, y: 1}}
                        end={{x: 0, y: 0}}
                        locations={[0, 1]}
                        colors={['#577189', '#526c84']}
                        style={{
                          height: 1,
                          width: '70%',
                          backgroundColor: '#778fa5',
                          marginTop: 40
                        }}
                      />
                      <TouchableOpacity
                        testID="settingpage"
                        onPress={() => {
                          this.state.popInternet === true &&
                            this.handleSettings();
                        }}
                        style={{
                          marginTop: 20,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-cog"
                            style={styles.iconnewCommon}></i>
                        </View>
                        <Text style={styles.iconnewCommonText}>Settings</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID="settingpage"
                        onPress={() => {
                          this.state.popInternet === true &&
                            this.handleDashboard();
                        }}
                        style={{
                          marginTop: 20,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                       <View style={{width: 30, height: 30}}>
                          <Image
                            source={require('../../../assets/bar-chart.png')}
                            style={{
                              width: 25,
                              height: 25,
                              resizeMode: 'contain',
                              alignSelf: 'center',
                              tintColor: '#7790A5'
                            }}
                          />
                        </View>
                        <Text style={styles.iconnewCommonText}>Statistics</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID="setFeedbackPopup"
                        onPress={
                          this.state.popInternet === true &&
                          this.setFeedback.bind(this)
                        }
                        style={{
                          marginTop: 20,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-lifebuoy"
                            style={styles.iconnewCommon}></i>
                          {/* <Image
                        source={SupportIcon}
                        style={{ width: "100%", height: "100%" }}
                        resizeMode="contain"
                      /> */}
                        </View>
                        <Text style={styles.iconnewCommonText}>
                          Get Support
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID="reportBug"
                        onPress={
                          this.state.popInternet === true &&
                          this.reportBug.bind(this)
                        }
                        style={{
                          marginTop: 30,
                          paddingHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}>
                        <View style={{width: 30, height: 30}}>
                          <i
                            className="icon-bug"
                            style={styles.iconnewCommon}></i>
                          {/* <Image
                        source={BugIcon}
                        style={{ width: "100%", height: "100%" }}
                        resizeMode="contain"
                      /> */}
                        </View>
                        <Text style={styles.iconnewCommonText}>
                          Report a bug
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{left: 10, bottom: 10}}>
                      <View
                        style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                        <View style={{width: 100, height: 100}}>
                          <Image
                            source={ExIcon}
                            style={{width: '100%', height: '100%'}}
                            resizeMode="contain"
                          />
                        </View>
                        <View style={{marginBottom: 10, marginLeft: 5}}>
                          <Text
                            style={{
                              fontFamily: fontFamily.font500,
                              fontSize: 14,
                              background:
                                ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                              webkitBackgroundClip: 'text',
                              webkitTextFillColor: 'transparent'
                            }}>
                            GroovePacker EX
                          </Text>
                          <Text
                            style={{
                              fontFamily: fontFamily.font500,
                              fontSize: 14,
                              background:
                                ' -webkit-linear-gradient(#768ea5 , #4b657d)',
                              webkitBackgroundClip: 'text',
                              webkitTextFillColor: 'transparent'
                            }}>
                            Expo v {Constants.expoVersion}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </CustomScrollBar>
                </View>
              </SafeAreaView>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    localLog: state.updateAsync,
    updateLog: state.saveLog,
    updateReportLog: state.saveLog.updateReportLog
  };
};

const mapDispatchToProps = {
  SetItem,
  GetItem,
  SubmitLog,
  CreateProduct,
  setBugOrSupportPopUP,
  resetBugOrSupportPopUP,
  CreateOrder,
  ScanPackBugReport
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
