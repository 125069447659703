import React, {useEffect, useState} from 'react';
import {StyleSheet, Dimensions} from 'react-native';
import {StackedBarChart} from 'react-native-chart-kit';
import {getStatisticsWithIn15MinDetails} from '../../../actions/statisticsAction';
import {useDispatch, useSelector} from 'react-redux';
const wp = Dimensions.get('window').width;
const hp = Dimensions.get('window').height;

const WithIn15MinChart = () => {
  const [orderCountList, setOrderCountList] = useState([]);
  const [legend, setLegend] = useState([]);

  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.list);

  useEffect(() => {
    dispatch(getStatisticsWithIn15MinDetails());
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [response]);

  const getDetails = async () => {
    let statistics = [];
    let userIds = [];

    const statisticsList = response.data.statistics;

    for (let i = 0; i < statisticsList.length; i++) {
      let list = statisticsList[i].data;
      let userId = statisticsList[i].user_id;

      let sum = 0;
      for (let j = 0; j < list.length; j++) {
        let object = list[j];
        sum = sum + object.scanned_item_count;
      }

      statistics.push([sum]);
      userIds.push(parseInt(userId));
    }

    setOrderCountList(statistics);

    let userDetails = response.data.user_details;
    let namesList = [];

    for (let i = 0; i < userDetails.length; i++) {
      for (let j = 0; j < userIds.length; j++) {
        if (userDetails[i].id == userIds[j]) {
          namesList.push(userDetails[i].name);
        }
      }
    }
    setLegend(namesList);
  };

  const data = {
    labels: ['', '', '', ''], // Customize as needed
    data: orderCountList, // Wrapped order counts
    legend: legend, // User names for legend
    barColors: ['#A9151A', '#CE7C04', '#994407', '#B1B91B']
  };

  const chartConfig = {
    backgroundGradientFrom: '#000000',
    backgroundGradientTo: '#000000',
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    barPercentage: 1.5,
    useShadowColorFromDataset: false,
    propsForLabels: {
      fontSize: (wp * 0.8) / 100,
      fontWeight: 'bold'
    }
  };

  return (
    <StackedBarChart
      style={styles.graphStyle}
      data={data}
      width={(wp * 60) / 100}
      height={(hp * 50) / 100}
      chartConfig={chartConfig}
      hideLegend={false}
    />
  );
};

export default WithIn15MinChart;

const styles = StyleSheet.create({
  graphStyle: {
    marginTop: 20,
    alignSelf: 'center',
    marginVertical: 8
  }
});
