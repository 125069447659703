import React, {useEffect, useState} from 'react';
import {StyleSheet, Dimensions} from 'react-native';
import {StackedBarChart} from 'react-native-chart-kit';
import {useDispatch, useSelector} from 'react-redux';
import {getStatisticsTodayVsYesterdayDetails} from '../../../actions/statisticsAction';
const wp = Dimensions.get('window').width;
const hp = Dimensions.get('window').height;

const TodayVsYesterdayChart = () => {
  const [orderCountList, setOrderCountList] = useState([]);
  const [legend, setLegend] = useState([]);

  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.list);

  useEffect(() => {
    dispatch(getStatisticsTodayVsYesterdayDetails());
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [response]);

  console.log('response:', response);

  const getDetails = async () => {
    let statistics = [];
    let userIds = [];

    const statisticsList = response.data.statistics;

    for (let i = 0; i < statisticsList.length; i++) {
      let userId = statisticsList[i].user_id;
      userIds.push(parseInt(userId));

      let todayDataList = statisticsList[i].data.today_data;
      let yesterdayDataList = statisticsList[i].data.yesterday_data;

      let todaySum = 0;
      if (todayDataList.length > 0) {
        for (let j = 0; j < todayDataList.length; j++) {
          todaySum = todaySum + todayDataList[j].scanned_item_count;
        }
      }

      statistics.push([todaySum]);

      let yesterdaySum = 0;

      if (yesterdayDataList.length > 0) {
        for (let k = 0; k < yesterdayDataList.length; k++) {
          yesterdaySum = yesterdaySum + yesterdayDataList[k].scanned_item_count;
        }
      }
      statistics.push([yesterdaySum]);
    }
    setOrderCountList(statistics);

    let userDetails = response.data.user_details;

    const selectedUsers = userIds.map(id => userDetails[id]);

    setLegend(selectedUsers);
  };

  const data = {
    labels: ['', '', '', ''],
    data: orderCountList,
    legend: legend,
    barColors: ['#A9151A', '#CE7C04', '#994407', '#B1B91B']
  };

  const chartConfig = {
    backgroundGradientFrom: '#000000',
    backgroundGradientTo: '#000000',
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    barPercentage: 1.5,
    useShadowColorFromDataset: false,
    propsForLabels: {
      fontSize: (wp * 0.8) / 100,
      fontWeight: 'bold'
    }
  };

  return (
    <StackedBarChart
      style={styles.graphStyle}
      data={data}
      width={(wp * 60) / 100}
      height={(hp * 50) / 100}
      chartConfig={chartConfig}
      hideLegend={false}
    />
  );
};

export default TodayVsYesterdayChart;

const styles = StyleSheet.create({
  graphStyle: {
    marginTop: 20,
    alignSelf: 'center',
    marginVertical: 8
  }
});
