import React, {useEffect, useState} from 'react';
import {View, Dimensions} from 'react-native';
import {BarChart} from 'react-native-svg-charts';
import {Text as SvgText} from 'react-native-svg';
import {useDispatch, useSelector} from 'react-redux';
import {getStatisticsDetails} from '../../../actions/statisticsAction';
const wp = Dimensions.get('window').width;
const hp = Dimensions.get('window').height;

const HorizontalChart = () => {
  const [orderCountList, setOrderCountList] = useState([]);
  const [legend, setLegend] = useState([]);

  const dispatch = useDispatch();
  const response = useSelector(state => state.statistics.list);

  useEffect(() => {
    dispatch(getStatisticsDetails());
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [response]);

  console.log('response:', response);

  const getDetails = async () => {
    let statistics = [];
    let userIds = [];

    const statisticsList = response.data.statistics;

    for (let i = 0; i < statisticsList.length; i++) {
      let list = statisticsList[i].data;
      let userId = statisticsList[i].user_id;

      let sum = 0;
      for (let j = 0; j < list.length; j++) {
        let object = list[j];
        sum = sum + object.scanned_item_count;
      }

      statistics.push(sum);
      userIds.push(parseInt(userId));
    }
    if (statistics.length == 1) {
      statistics.push(0);
      statistics.push(0);
      statistics.push(0);
    }
    setOrderCountList(statistics);

    let userDetails = response.data.user_details;
    let namesList = [];

    for (let i = 0; i < userDetails.length; i++) {
      for (let j = 0; j < userIds.length; j++) {
        if (userDetails[i].id == userIds[j]) {
          namesList.push(userDetails[i].name);
        }
      }
    }
    setLegend(namesList);
  };

  const Labels = ({x, y, bandwidth, data}) =>
    data.map((item, index) => (
      <SvgText
        key={index}
        x={x(item.value) + 10} // Adjust label position
        y={y(index) + bandwidth / 2}
        fontSize={14}
        fill="white"
        alignmentBaseline="middle">
        {item.value}
      </SvgText>
    ));

  return (
    <View
      style={{
        width: (wp * 60) / 100,
        height: (hp * 45) / 100,
        alignSelf: 'center',
        backgroundColor: '#000000',
        padding: 20,
        marginBottom: (wp * 1) / 100
      }}>
      <BarChart
        style={{height: (hp * 40) / 100}}
        data={orderCountList}
        horizontal={true}
        svg={{fill: 'red'}}
        contentInset={{top: 10, bottom: 10, left: 10, right: 10}}
        gridMin={0}
      />
    </View>
  );
};

export default HorizontalChart;
